!(function (t, e, i, o) {
    t(e).on(
        "load resize",
        IU.utils.throttle(function () {
            enquire.register("screen and (min-width: 40em)", {
                match: function () {
                    t(".skirt-nav .accordion-title").attr("disabled", !0), t(".skirt-nav .accordion-content").attr("aria-hidden", "false"), t(".skirt-nav .accordion-content").css({ display: "block" });
                },
                unmatch: function () {
                    t(".skirt-nav .accordion-title").attr("disabled", !1), t(".skirt-nav .accordion-content").attr("aria-hidden", "true"), t(".skirt-nav .accordion-content").css({ display: "none" });
                },
                setup: function () { },
                deferSetup: !0,
                destroy: function () { },
            });
        }, 250)
    );
})(jQuery, window, window.document),
    (function (t, e, i, o) {
        "use strict";
        e.history.location || e.location;
        var n = e.IU || {};
        function s(e, i, o) {
            (this.$form = e),
                (this.$selections = t("#filter-selection")),
                (this.$pagination = t("#filter-pagination")),
                (this.$mobilePagination = t("#filter-pagination-mobile")),
                (this.$results = t("#filter-results")),
                (this.yourSelections = {}),
                (this.settings = o),
                (this.usePopover = void 0 === this.$form.data("popover") || this.$form.data("popover")),
                (this.useSelections = void 0 === this.$form.data("selections") || this.$form.data("selections")),
                this.setup();
        }
        (s.prototype.setup = function () {
            this.settings.handlePageLoad && this.handlePageLoad(),
                (this.submitPath = this.formSubmitPath()),
                this.bindFormSubmit(),
                this.bindClickEvents(),
                this.bindSelectEvents(),
                this.bindTextInput(),
                this.bindNumberInput(),
                this.bindRadioEvents(),
                this.bindCheckboxEvents(),
                this.bindPaginationEvents(),
                this.usePopover && this.buildPopover(),
                this.hideSubmitButton();
        }),
            (s.prototype.hideSubmitButton = function () {
                this.$form.removeClass("grid-has-submit"), t('button[type="submit"]', this.$form).hide();
            }),
            (s.prototype.ajaxScroll = function (e) {
                t("html, body").animate({ scrollTop: this.$results.offset().top - e }, 1e3);
            }),
            (s.prototype.formSubmitPath = function () {
                var t = this.$form.attr("action");
                return void 0 !== this.$form.data("api") && (t = this.$form.data("api")), t;
            }),
            (s.prototype.bindFormSubmit = function () {
                var e = this,
                    i = e.$form;
                i.on("submit", function (o, n) {
                    o.preventDefault();
                    var s = e.yourSelections,
                        r = e.createQueryString(s);
                    (r = i.serialize()),
                        e.addSpinner(),
                        e.settings.dev ||
                        t.ajax({
                            url: e.submitPath,
                            data: r,
                            method: i.attr("method"),
                            dataType: "html",
                            success: function (t) {
                                e.handleResponse(t);
                            },
                        }),
                        history.replaceState(r, null, "?" + r);
                });
            }),
            (s.prototype.bindClickEvents = function () {
                var e = this;
                e.$selections.on("click", "a.select-item", function (i) {
                    i.preventDefault();
                    var o = t(this).data("name");
                    e.removeSelection(o), e.$form.trigger("submit");
                });
            }),
            (s.prototype.bindTextInput = function () {
                var e = this;
                t("input[type='text']", e.$form).on(
                    "keyup",
                    n.utils.throttle(function (i) {
                        var o = t(this),
                            n = o.attr("name"),
                            s = "Search text: " + o.val(),
                            r = o.val();
                        e.selectionCrud(o, n, r, s);
                    }, 750)
                );
            }),
            (s.prototype.bindNumberInput = function () {
                var e = this;
                t("input[type='number']", e.$form).on("input", function (i) {
                    var o = t(this),
                        n = o.attr("name"),
                        s = "Search text: " + o.val(),
                        r = o.val();
                    e.selectionCrud(o, n, r, s);
                });
            }),
            (s.prototype.bindCheckboxEvents = function () {
                var e = this;
                t("input[type='checkbox']", e.$form).on("change", function (i) {
                    var o = t(this),
                        n = o.closest(".alpha.checkboxes"),
                        s = o.attr("name"),
                        r = o.siblings("label").text(),
                        a = o.is(":checked") ? o.val() : "";
                    n.length && n.find('input[type="checkbox"]').not(o).prop("checked", !1), e.selectionCrud(o, s, a, r);
                });
            }),
            (s.prototype.bindRadioEvents = function () {
                var e = this;
                t("input[type='radio']", e.$form).on("change", function (i) {
                    e.$form.trigger("submit");
                    var o = t(this),
                        n = o.attr("name"),
                        s = o.val(),
                        r = o.val();
                    e.selectionCrud(o, n, r, s);
                }),
                    t("input[type='radio']", e.$form).on("");
            }),
            (s.prototype.bindSelectEvents = function () {
                var e = this;
                t("select[data-ajax='true']").on("change", function () {
                    var i = t(this),
                        o = i.data("results-endpoint"),
                        n = i.data("controls");
                    (({}[i.attr("name")] = i.val()), i.trigger("IU.reset-ajax-child"));
                    var s = t(n);
                    s.empty(),
                        t.ajax({
                            url: o,
                            method: e.$form.attr("method"),
                            data: e.$form.serialize(),
                            success: function (e, i, o) {
                                if (o.getResponseHeader("Content-Type").includes("text/html")) {
                                    var r = t(e).find(n).html();
                                    s.html(r);
                                } else
                                    t.each(e, function (t, e) {
                                        s.append("<option value='" + e.id + "'>" + e.name + "</option>");
                                    });
                            },
                        });
                }),
                    t("select[data-ajax='true']").on("IU.reset-ajax-child", function (i) {
                        var o = t(this).data("controls"),
                            n = t(o),
                            s = n.attr("name");
                        e.removeSelection(s), t(":first", n).prop("selected", !0), t(":not(:first)", n).remove();
                    }),
                    e.$form.on("change", "select", function () {
                        var i = t(this),
                            o = t("option:selected", i),
                            n = i.attr("name"),
                            s = o.text(),
                            r = o.val();
                        e.selectionCrud(i, n, r, s);
                    });
            }),
            (s.prototype.bindPaginationEvents = function () {
                var e = this;
                e.$mobilePagination.find("a.selector").removeAttr("href"),
                    e.$mobilePagination.on("click", "a.button.next, a.button.previous", function (i) {
                        i.preventDefault();
                        var o = t(this).attr("href");
                        e.paginationSubmit(o, 80);
                    }),
                    e.$mobilePagination.on("change", "select.pagination", function (i) {
                        i.preventDefault();
                        var o = t(this),
                            n = t("option:selected", o).val();
                        e.paginationSubmit(n, 80);
                    }),
                    t(e.$pagination).on("click", "a", function (i) {
                        i.preventDefault();
                        var o = t(this);
                        t("li", e.$pagination).removeClass("current"), o.closest("li").addClass("current");
                        var n = o.attr("href");
                        e.paginationSubmit(n, 100);
                    });
            }),
            (s.prototype.paginationSubmit = function (e, i) {
                var o = this;
                o.ajaxScroll(i),
                    t.get(o.submitPath + e, function (t) {
                        o.handleResponse(t);
                    }),
                    history.replaceState(e, null, e);
            }),
            (s.prototype.handleResponse = function (e) {
                (e = t("<div />").append(jQuery.parseHTML(e))),
                    this.replaceResults(e),
                    this.replacePagination(e),
                    this.removeSpinner(),
                    this.replaceCount(e),
                    n.helpers && n.helpers.emailObfuscation.call(n),
                    n.helpers && n.helpers.externalLinksInNewTabs.call(n);
            }),
            (s.prototype.replaceResults = function (e) {
                var i = t(e).find("#filter-results, #results").html();
                (i = void 0 === i ? "&nbsp;" : i), this.$results.html(i), this.$results.find("a:first").focus();
            }),
            (s.prototype.replacePagination = function (e) {
                var i = t("#filter-pagination", e).html(),
                    o = t("#filter-pagination-mobile", e).html();
                (i = void 0 === i ? "&nbsp;" : i), (o = void 0 === o ? "&nbsp;" : o), this.$pagination.html(i), this.$mobilePagination.html(o), this.$mobilePagination.find("a.selector").removeAttr("href");
            }),
            (s.prototype.replaceCount = function (e) {
                var i = t("#filter-selection .result-count", e),
                    o = i.data("label") && i.data("label").length ? i.data("label") : "results found",
                    n = t("#filter-selection .result-count span", e).html(),
                    s = n ? "<span>" + n + "</span> " + o : "<span></span>";
                t(".result-count").html(s);
            }),
            (s.prototype.selectionCrud = function (t, e, i, o) {
                if ((delete this.yourSelections.page, "checkbox" === t.attr("type") && (t = t.closest(".form-item-input")), t.data("isFiltered"))) {
                    if ("" === i || "0" === i || "all" === i) return this.removeSelection(e), n.debug("Remove Selection"), void this.$form.trigger("submit");
                    if ("" !== i) return this.updateSelection(e, i, o), n.debug("Update Selection"), void this.$form.trigger("submit");
                }
                if ("" !== i || "0" !== i || "all" !== i) return this.addSelection(t, e, i, o), n.debug("Add Selection"), t.data("isFiltered", !0), void this.$form.trigger("submit");
            }),
            (s.prototype.addSelection = function (e, i, o, n) {
                var s = t("<a>", { class: "select-item", href: "#", text: n, "data-name": i, "data-value": o });
                (this.yourSelections[i] = o), this.yourSelections.hasOwnProperty("clear-all") || this.buildClearButton(), t(s).insertBefore(".clear", this.$selections);
            }),
            (s.prototype.updateSelection = function (e, i, o) {
                var n = t('[data-name="' + e + '"]', this.$selections);
                (this.yourSelections[e] = i), n.attr("data-value", i), n.text(o), o.length || this.removeSelection(e);
            }),
            (s.prototype.removeSelection = function (e) {
                var i = t('[data-name="' + e + '"]', this.$selections);
                "clear-all" !== e && this.resetFormItem(e), ("clear-all" !== e && 1 !== Object.keys(this.yourSelections).length) || this.clearAllSelections(), i.remove();
            }),
            (s.prototype.resetFormItem = function (e) {
                var i = this.$form.find('[name="' + e + '"]');
                i.is(":text") && i.val(""),
                    i.is("select") && t(":first", i).prop("selected", !0),
                    i.is(":checkbox") && (i.prop("checked", !1), i.closest(".form-item-input").removeData("isFiltered")),
                    "letter" === e && ((i = t(".form-item.alpha")), t(".form-item.alpha li").removeClass("current")),
                    i.removeData("isFiltered"),
                    delete this.yourSelections[e];
            }),
            (s.prototype.buildClearButton = function () {
                this.yourSelections["clear-all"] = "Clear selections";
                var e = t("<a>", { class: "select-item clear", href: "#", text: "Clear selections", "data-name": "clear-all", "data-value": "clear-all" });
                t(".content", this.$selections).append(e);
            }),
            (s.prototype.clearAllSelections = function () {
                for (name in this.yourSelections) this.resetFormItem(name);
                (this.yourSelections = {}), t(".content", this.$selections).html("");
            }),
            (s.prototype.buildPopover = function () {
                var e = this;
                if (e.usePopover) {
                    var i = t('<div class="popoverScreen" />'),
                        o = t("#filter-selection");
                    (e.$popoverScreen = i),
                        e.$form.before(i),
                        e.buildFilterControls(),
                        e.addViewResultsButton(),
                        enquire.register("screen and (max-width: 40em)", {
                            match: function () {
                                e.$form.addClass("popover"), t(".result-count", o).hide(), e.$filterControls.show(), e.$viewResultsButton.show();
                            },
                            unmatch: function () {
                                e.closePopover(), e.$form.removeClass("popover"), e.$filterControls.hide(), e.$viewResultsButton.hide(), t(".result-count", o).show();
                            },
                        });
                }
            }),
            (s.prototype.buildFilterControls = function (e) {
                var o = this,
                    n = t(".result-count", o.$selections).html();
                void 0 === n && (n = "");
                var s = t('<div id="filter-controls" class="clearfix" />'),
                    r = t('<p class="float-left left result-count">' + n + "</p>"),
                    a = t('<div class="float-right right"><a href="#" class="open-popover button button-small">Filter Results</a></div>');
                s.append(r),
                    s.append(a),
                    s.hide(),
                    t(i).on("click", "a.open-popover", function (t) {
                        t.preventDefault(), o.openPopover();
                    }),
                    (o.$filterControls = s),
                    o.$filterControls.insertBefore(o.$form);
            }),
            (s.prototype.addViewResultsButton = function () {
                var e = t('<button type="button" class="button close">View Results</button>');
                e.hide(), this.$form.append(e), (this.$viewResultsButton = e);
            }),
            (s.prototype.openPopover = function () {
                var e = t(".filter-title .label", this.$form);
                n.preventHTMLScroll(),
                    e.length ? e.addClass("modal-title").append("<span class='close right'>&times;</span>") : ((e = t("<h4 class='modal-title custom'>Filters <span class='close right'>&times;</span></h4>")), this.$form.prepend(e)),
                    this.$form.addClass("open"),
                    this.$popoverScreen.addClass("open"),
                    this.$form.on("click", ".close", this.closePopover.bind(this)),
                    this.$popoverScreen.on("click", this.closePopover.bind(this));
            }),
            (s.prototype.closePopover = function () {
                this.$form.removeClass("open"), n.enableHTMLScroll(), t(".modal-title.custom", this.$form).remove(), this.$form.find(".close.right").remove(), this.$popoverScreen.removeClass("open");
            }),
            (s.prototype.handlePageLoad = function () {
                var t = this.getQueryParameters();
                for (var e in ((this.yourSelections = t || {}), this.yourSelections)) {
                    this.$form.find('[name="' + e + '"]');
                    "all" !== this.yourSelections[e] || delete this.yourSelections[e];
                }
                this.setupFilters(), Object.keys(this.yourSelections).length && (this.yourSelections["clear-all"] = "Clear selections");
            }),
            (s.prototype.setupFilters = function () {
                n.debug("Setup Filters");
                e.location.search;
                var i = this.getQueryParameters();
                for (var o in i)
                    if (i.hasOwnProperty(o)) {
                        var s = t("select[name=" + o + "]"),
                            r = t("input[type=checkbox][name=" + o + "]"),
                            a = t("input[type=text][name=" + o + "]");
                        if (s.length) {
                            s.val(i[o]);
                            var l = t("option:selected", s),
                                c = s.attr("name"),
                                u = l.text(),
                                d = l.val();
                            this.addSelection(s, c, d, u), s.data("isFiltered", !0);
                            continue;
                        }
                        if (r.length) {
                            if (r.closest(".alpha.checkboxes").length) {
                                t(":checkbox[value=" + i[o] + "]", this.$form).prop("checked", !0);
                                (c = r.attr("name")), (u = i[o]), (d = i[o]);
                                r.closest(".form-item-input").data("isFiltered", !0);
                            } else {
                                r.prop("checked", !0);
                                (c = r.attr("name")), (u = r.next("label").text()), (d = r.next("label").text());
                            }
                            this.addSelection(r, c, d, u);
                            continue;
                        }
                        if (a.length) {
                            a.val(i[o]);
                            (c = a.attr("name")), (u = "Search text: " + a.val()), (d = a.val());
                            this.addSelection(a, c, d, u), a.data("isFiltered", !0);
                            continue;
                        }
                    }
            }),
            (s.prototype.getQueryParameters = function (t) {
                return (
                    (!(t = t || i.location.search) && {}) ||
                    t
                        .replace(/(^\?)/, "")
                        .split("&")
                        .map(
                            function (t) {
                                return (this[(t = t.split("="))[0]] = decodeURIComponent(t[1])), this;
                            }.bind({})
                        )[0]
                );
            }),
            (s.prototype.createQueryString = function (t) {
                var e = [];
                for (var i in t) t.hasOwnProperty(i) && "clear-all" !== i && e.push(encodeURIComponent(i) + "=" + encodeURIComponent(t[i]));
                return e.join("&");
            }),
            (s.prototype.addSpinner = function () {
                t(".result-count").addClass("loading"), t(".result-count span").html("");
            }),
            (s.prototype.removeSpinner = function () {
                t(".result-count").removeClass("loading");
            }),
            (n.FormFilter = s),
            n.addInitalisation("form-filter", function () {
                var e = arguments[0];
                t("form.filter").each(function (i) {
                    var o = t(this);
                    if (o.data("isFormFilter")) return !0;
                    o.data("isFormFilter", !0);
                    new n.FormFilter(o, i, e);
                });
            }),
            n.UIModule({
                module: "form-filter",
                settings: { dev: !1, handlePageLoad: !0 },
                init: function () {
                    n.initialize("form-filter", this.settings);
                },
            });
    })(jQuery, window, window.document),
    (function (t, e, i, o) {
        "use strict";
        function n(e, i) {
            IU.debug("Module: Features Carousel [overridden]  - " + i),
                e.slick({
                    slide: ".grid-item",
                    infinite: !0,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: !1,
                    responsive: [
                        { breakpoint: 860, settings: { slidesToShow: 2, dots: !1 } },
                        { breakpoint: 640, settings: { slidesToShow: 1, centerMode: !0, centerPadding: "12px", dots: !0 } },
                    ],
                    onInit: function (e) {
                        e.$list.prop("tabIndex", "-1"),
                            t(".slick-slide", e.$list).each(function () {
                                var e = t(this);
                                0 === e.find(".inner").length && e.wrapInner('<div class="inner"></div>'), e.hasClass("slick-active") || (e.attr("aria-hidden", !0), e.find("a").attr("tabindex", -1));
                            });
                    },
                    onSetPosition: function (e) {
                        var i = this,
                            o = t(".content", i.$slides);
                        o.css({ minHeight: "" });
                        var n = t(i.$slides[0]).find("img").height() / 2;
                        if (n) !1 !== i.options.dots && i.$dots.css({ top: 2 * n }), i.$slider.removeClass("no-images");
                        else {
                            var s = o
                                .map(function () {
                                    return t(this).find("h2").outerHeight();
                                })
                                .get();
                            (n = Math.max.apply(null, s) / 2 + 50), i.$slider.addClass("no-images");
                        }
                        null !== i.$nextArrow && i.$nextArrow.css({ top: n }), null !== i.$prevArrow && i.$prevArrow.css({ top: n }), t(".slick-slide", i.$list).removeClass("first"), t(".slick-active:first", i.$list).addClass("first");
                        var r = o
                            .map(function () {
                                return t(this).outerHeight();
                            })
                            .get(),
                            a = o
                                .map(function () {
                                    return t(this).find(".button").outerHeight();
                                })
                                .get(),
                            l = Math.max.apply(null, r) + (Math.max.apply(null, a) + 20);
                        t(".slick-slide .content", i.$list).css({ minHeight: l });
                    },
                });
        }
        IU.addInitalisation("features-carousel", function () {
            t(".carousel-features .grid").each(function (e) {
                var i = t(this);
                if (i.data("isFeaturesCarousel")) return !0;
                i.data("isFeaturesCarousel", !0), new n(i, e);
            });
        }),
            IU.UIModule({
                module: "features-carousel",
                requireSlick: ".carousel-features",
                init: function () {
                    IU.initialize("features-carousel");
                },
            });
    })(jQuery, window, window.document),
    (function (t, e, i, o) {
        var n = function () {
            this.init();
        };
        (n.prototype.init = function () {
            "use strict";
            this.handleSocialGrid(), this.buttonGroup(), this.scrollToTopLinks();
        }),
            (n.prototype.handleSocialGrid = function () {
                var t = e.querySelector("#social-grid");
                t &&
                    (i.getScript("//cdnjs.cloudflare.com/ajax/libs/masonry/3.3.0/masonry.pkgd.min.js", function () {
                        imagesLoaded(t, function () {
                            new Masonry(t, { columnWidth: ".grid-sizer", itemSelector: ".item", percentPosition: !0 });
                        });
                    }),
                        i("#social-grid .item a").focus(function () {
                            i("#social-grid .item").removeClass("active-block"), i(this).parent(".item").addClass("active-block");
                        }));
            }),
            (n.prototype.buttonGroup = function () {
                i(".button-group").length > 0 &&
                    i(".button-group").each(function () {
                        var t = i(this).outerWidth();
                        (buttonGroupItemTotal = 0),
                            i(this)
                                .find("li")
                                .each(function () {
                                    buttonGroupItemTotal += i(this).outerWidth();
                                }),
                            buttonGroupItemTotal > t && i(this).find("li").css({ marginLeft: 0, display: "block" });
                    });
            }),
            (n.prototype.scrollToTopLinks = function () {
                i(".section.scroll-to-top .layout ul").each(function () {
                    var t = i("<a class='back-to-top' href='#content' title='Back to top'>Back to top</a>");
                    i(t).insertAfter(this),
                        i(t).on("click", function () {
                            setTimeout(function () {
                                i("#Numbers").find("a:first")[0].focus();
                            }, 1e3);
                        });
                }),
                    i("#content .visually-hidden + p a").on("click", function () {
                        var t = i(this).prop("hash");
                        setTimeout(function () {
                            i(t).find("a:first")[0].focus();
                        }, 1e3);
                    });
            });
        new n();
    })(window, window.document, jQuery),
    (function (t, e, i, o) {
        i(e).ready(function () {
            (Foundation.OffCanvas.defaults.transitionTime = 500), (Foundation.OffCanvas.defaults.forceTop = !1), (Foundation.OffCanvas.defaults.positiong = "right"), (Foundation.Accordion.defaults.allowAllClosed = !0), i(e).foundation();
            var o = t.IUSearch || {},
                n = t.IU || {};
            //o.init({ CX: { site: "016278320858612601979:w_w23ggyftu", all: "016278320858612601979:ddl1l9og1w8" }, wrapClass: "row pad", searchBoxIDs: ["search", "off-canvas-search"] }), n.init && n.init({ debug: !0 });
        });
    })(window, window.document, jQuery),
    document.addEventListener("DOMContentLoaded", function () {
        let t = [].slice.call(document.querySelectorAll("img.rvt-c-lazy-load")),
            e = !1;
        const i = function () {
            !1 === e &&
                ((e = !0),
                    setTimeout(function () {
                        t.forEach(function (e) {
                            e.getBoundingClientRect().top <= window.innerHeight &&
                                e.getBoundingClientRect().bottom >= 0 &&
                                "none" !== getComputedStyle(e).display &&
                                ((e.src = e.dataset.src),
                                    e.classList.remove("rvt-c-lazy-load"),
                                    0 ===
                                    (t = t.filter(function (t) {
                                        return t !== e;
                                    })).length && (document.removeEventListener("scroll", i), window.removeEventListener("resize", i), window.removeEventListener("orientationchange", i)));
                        }),
                            (e = !1);
                    }, 200));
        };
        t.length && (document.addEventListener("scroll", i), window.addEventListener("resize", i), window.addEventListener("orientationchange", i));
    }),
    document.addEventListener("DOMContentLoaded", function () {
        if (window.document.documentMode)
            for (var t = document.querySelectorAll("[data-ie-video]"), e = 0; e < t.length; e++) {
                var i = t[e].getAttribute("data-src");
                t[e].setAttribute("src", i);
            }
    });
